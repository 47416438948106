import { useLoaderData } from "react-router-dom"

import { Log } from "../Log"
import { TLog } from "../../Utils/data"

type TLoaderData = {
  title?: string
  logs: TLog[]
}

export const LogsPage = () => {
  const data = useLoaderData() as TLoaderData
  const { logs } = data
  const logsByLatest = logs.sort((a, b) => Number(b.id) - Number(a.id))

  return <>
    {logsByLatest.map((log: TLog) => {
      return <div key={log.id} className="lg:grid lg:grid-cols-2">
        <Log data={log} shouldShowCategory={true} />
      </div>
    })}
  </>
}
