import { Block } from "konsta/react";
import { useRouteError } from "react-router-dom";
import { TopNavigation } from "../TopNavigation"
import { BottomNavigation } from "../BottomNavigation"

export const ErrorPage = () => {
  const error = useRouteError() as {
    statusText?: string
    message?: string
  };

  return (
    <>
      <TopNavigation />
      <BottomNavigation />

      <Block>
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>

          <br /><br />
          <hr />
          <br /><br />

          <code>
            {error.statusText || error.message}
          </code>
        </div>
      </Block>
    </>
  );
}
