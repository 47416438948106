import { useRef, useState } from "react"
import { RxPencil2, RxReader, RxTrash } from "react-icons/rx"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Block,
  Card,
  Icon,
  Link,
  List,
  ListItem,
  Navbar,
  Page,
  Popover,
  Popup
} from 'konsta/react'
import { getCategoryName, deleteLog, TLog } from "../../Utils/data"

type TProps = {
  data: TLog
  shouldShowCategory?: boolean
}

export const Log = ({ data, shouldShowCategory }: TProps) => {
  const {
    id,
    category_id,
    image,
    added,
    name,
    iso,
    ss,
    f,
    film,
    notes
  } = data

  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false)
  const popoverTargetRef = useRef(null);
  const navigate = useNavigate()
  const location = useLocation()

  const openPopover = (targetRef: any) => {
    popoverTargetRef.current = targetRef;
    setPopoverOpened(true);
  };

  const removeLog = (logId: string, name: string) => {
    const shouldDelete = window.confirm(`Are you sure you want to delete the log "${name}"?\n\nThis action can not be undone.`)

    if (shouldDelete) {
      deleteLog(logId)
      navigate(location)
    }
  }

  return <>
    <Card
      outline
      footer={
        <>
          <div className="flex justify-between">
            <Link onClick={() => navigate(`/log/${id}/edit`)}>
              <Icon ios={<RxPencil2 className='w-6 h-6 mr-2' />} />
              Edit
            </Link>
            <Link onClick={() => removeLog(id, name)}>
              <Icon ios={<RxTrash className="w-6 h-6" />} />
              Delete
            </Link>
            <Link
              id={`log-${id}`}
              className='notes-link'
              onClick={() => openPopover(`#log-${id}`)}
            >
              Notes
              <Icon ios={<RxReader className='w-6 h-6 ml-2' />} />
            </Link>
          </div>
        </>
      }
    >
      {image && <div
        className="ios:-mx-4 ios:-mt-4 h-48 p-4 flex items-end text-white ios:font-bold bg-cover bg-center material:rounded-xl mb-4 material:text-[22px]"
        style={{
          backgroundImage: `url(${image})`,
        }}
        onClick={() => setPopupOpen(true)}
      >{name}</div>
      }
      <div className="grid grid-cols-2 text-gray-500 mb-3">
        <div>{
          new Intl.DateTimeFormat('en-DE', {
            dateStyle: 'long',
            timeZone: 'Europe/Berlin'
          }).format(new Date(added))
        }</div>
        <div className="text-right text-gray-600">#{id}</div>
      </div>
      <List strongIos outlineIos className='mb-0'>

        {shouldShowCategory === true &&
          <ListItem title='Category' after={getCategoryName(category_id)} />
        }
        <ListItem title='Film' after={film} />
        <ListItem title='ISO' after={iso} />
        <ListItem title='Shutter Speed' after={ss} />
        <ListItem title='Apperture (f)' after={f} />
      </List>
    </Card>

    <Popover
      opened={popoverOpened}
      target={popoverTargetRef.current}
      onBackdropClick={() => setPopoverOpened(false)}
    >
      <Block>{notes}</Block>
    </Popover>

    <Popup opened={isPopupOpen} onBackdropClick={() => setPopupOpen(false)}>
      <Page>
        <Navbar
          title="Image detail"
          right={
            <Link navbar onClick={() => setPopupOpen(false)}>
              Close
            </Link>
          }
        />
        <img src={image} alt={name} />
        <Block strong>{notes}</Block>
      </Page>
    </Popup>
  </>
}
