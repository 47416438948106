import { useParams } from 'react-router-dom'
import {
  BlockTitle,
} from 'konsta/react'

import { LogForm } from "../LogForm"

export const LogAddPage = () => {
  const { categoryName } = useParams()

  return <>
    <BlockTitle>Add log to: {categoryName}</BlockTitle>

    <LogForm />
  </>
}
