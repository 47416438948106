import { useParams } from 'react-router-dom'

import { Log } from '../Log'
import { LoadLog } from '../../Utils/data'

export const LogPage = () => {
  const { logId } = useParams()

  if (logId) {
    const log = LoadLog(logId)
    return <Log data={log} />
  } else {
    return <h2>We had a problem</h2>
  }
}
