import { useNavigate, useLocation, useRouteLoaderData } from "react-router-dom";
import { Icon, Navbar, Link } from "konsta/react"
import { RxBackpack } from "react-icons/rx"

export const TopNavigation = () => {
  const RERouteId = /^\/(\w+)/
  const navigate = useNavigate()
  const routeLocation = useLocation()
  const pathname = routeLocation.pathname;
  let routeId = ''

  if (pathname.includes('log/add')) routeId = 'logAdd'
  if (pathname.includes('category/add')) routeId = 'categoryAdd'
  if (pathname.match(/log\/\d*\/edit/g)) routeId = 'logEdit'
  if (routeId === '') routeId = RERouteId.exec(routeLocation.pathname)?.at(1) || 'root'

  let addIcon = <></>
  let addLink = ''

  switch (true) {
    case pathname.includes('categories'):
    case pathname === '/':
      addLink = '/category/add'
      addIcon = <Icon
        ios={<RxBackpack className="w-6 h-6" />}
        badge="+"
      />
      break
    default:
      addIcon = <></>
      break
  }

  const routeLoderData = useRouteLoaderData(routeId)
  const { title }: any = routeLoderData ? routeLoderData : ''

  return <Navbar
    title={title}
    right={
      addLink.length > 0 && <Link
        navbar
        onClick={() => navigate(addLink)}>
        {addIcon}
      </Link>
    }
  />
}
