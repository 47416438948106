import { useParams, useNavigate } from "react-router-dom"
import { BiPlus } from "react-icons/bi"
import {
  BlockTitle,
  Icon,
  Link,
} from 'konsta/react'
import { Log } from '../Log'
import { getCategoryName, logsFromCategory, TLog } from "../../Utils/data"

type TParams = {
  categoryId: string
}

export const CategoryPage = () => {
  const navigate = useNavigate()

  const { categoryId } = useParams<keyof TParams>() as TParams
  const categoryLogs: TLog[] = categoryId
    ? logsFromCategory(categoryId).sort((a, b) => Number(b.id) - Number(a.id))
    : []
  const categoryName = getCategoryName(categoryId)

  return <>
    <BlockTitle withBlock={false}>
      {categoryName} ({categoryLogs.length})

      <Link onClick={() => navigate(`/log/add/${categoryId}/${categoryName}`)}>
        <Icon
          ios={<BiPlus className='w-7 h-7' />}
        />
      </Link>
    </BlockTitle>

    {categoryLogs.map((log) => {
      return <div key={log.id} className="lg:grid lg:grid-cols-2">
        <Log data={log} />
      </div>
    })}
  </>
}
