import { useState } from 'react'
import { Block, Button, List, ListInput } from 'konsta/react'
import { generateId, addCategory, Load, TCategory } from '../../Utils/data'
import { useNavigate } from 'react-router-dom'

export const CategoryAddPage = () => {
  const [categoryName, setCategoryName] = useState('')
  const categories: TCategory[] = Load('categories')
  const navigate = useNavigate()

  const categoryExists = (categoryName: string): boolean => {
    let categoryExist = false

    categories && categories.forEach(category => {
      if (category.name === categoryName) {
        categoryExist = true
      }
    })

    return categoryExist
  }

  const prepareCategoryToAdd = () => {
    const id = generateId(categories)
    const name = categoryName.trim()

    if (!name) {
      alert("Category name is invalid")
      return false
    }

    if (!categoryExists(name)) {
      if (name.length < 0) {
        alert('Make sure you enter a valid name')
        return false
      }

      addCategory({
        id,
        name: categoryName
      })

      navigate('/')
      return false
    }

    alert("Category already exist")
  }

  const onCategoryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryName(e.target.value)
  }

  return <>
    <List strongIos insetIos>
      <ListInput
        clearButton
        autoFocus
        id="categoryName"
        maxLength={45}
        label="Category name"
        type="text"
        placeholder="Bright flower"
        value={categoryName}
        onClear={() => setCategoryName('')}
        onChange={onCategoryNameChange}
      />
    </List>
    <Block>
      <Button large onClick={() => prepareCategoryToAdd()}>Add</Button>
    </Block>
  </>
}