import { useLocation, useNavigate } from "react-router-dom"
import { Icon, Tabbar, TabbarLink } from "konsta/react"
import { RxBackpack } from "react-icons/rx"
import { SlFilm } from "react-icons/sl"
import { MdImportExport } from "react-icons/md"
import { Load } from "../../Utils/data"

export const BottomNavigation = () => {
  const navigate = useNavigate()
  const routeLocation = useLocation()
  const logs = Load('logs')
  let active = 'categories'
  if (routeLocation.pathname.includes('log')) active = 'logs'
  if (routeLocation.pathname.includes('share')) active = 'share'

  return <Tabbar labels icons className="left-0 bottom-0 fixed">
    <TabbarLink
      active={active === 'categories'}
      label="Categories"
      icon={
        <Icon
          ios={<RxBackpack className='w-7 h-7' />}
        />
      }
      onClick={() => navigate('/')}
    />
    {logs && logs.length > 0 && <TabbarLink
      active={active === 'logs'}
      label="Logs"
      icon={<SlFilm className="w-7 h-7" />}
      onClick={() => navigate('/logs')}
    />}
    <TabbarLink
      active={active === 'share'}
      label="Share"
      icon={<MdImportExport className="w-7 h-7" />}
      onClick={() => navigate('/share')}
    />
  </Tabbar>
}
