import { useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import {
  Block,
  Button,
  List,
  ListInput,
  Toast
} from 'konsta/react'

import { addLog, editLog, generateId, Load, TLog } from '../../Utils/data'

type TProps = {
  log?: TLog
}

export const LogForm = ({ log }: TProps) => {
  const navigate = useNavigate()
  const { categoryId } = useParams()
  const logs: TLog[] = Load('logs')
  const initialLogState: TLog = {
    id: log ? log.id : generateId(logs),
    image: log ? log.image : "",
    category_id: log ? log.category_id : categoryId || "1",
    added: log ? log.added : "",
    name: log ? log.name : "",
    iso: log ? log.iso : 0,
    ss: log ? log.ss : "",
    f: log ? log.f : "",
    film: log ? log.film : "",
    notes: log ? log.notes : "",
  }

  const callToAction = log ? 'Save' : 'Add'
  const [logData, setLogData] = useState<TLog>(initialLogState)
  const [isToastOpen, setToastOpen] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>('')
  const onChangeLog = (key: keyof TLog, value: string) => {
    const updatedLogData = logData
    updatedLogData[key] = value as never
    setLogData(updatedLogData)
  }

  const editLogAction = (id: string) => {
    editLog(id, logData)
  }

  const executeAction = (): void => {
    if (log) {
      editLogAction(logData.id)
      setToastText('Log saved!')
      setToastOpen(true)

      navigate(`/log/${logData.id}`)
    } else {
      addLog(logData)
      setToastText('Log added!')
      setToastOpen(true)

      navigate(`/category/${categoryId}`)
    }
  }

return <>
  <List strongIos insetIos>
    <ListInput
      required
      autoFocus
      maxLength={255}
      type="url"
      label="Image URL"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('image', e.target.value)}
      placeholder="https://"
      defaultValue={initialLogState.image}
    />

    <ListInput
      required
      maxLength={45}
      type="text"
      label="Log name"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('name', e.target.value)}
      defaultValue={initialLogState.name}
      placeholder="Bright flower" />

    <ListInput
      required
      maxLength={45}
      type="text"
      label="Film"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('film', e.target.value)}
      defaultValue={initialLogState.film}
      placeholder="Kodak Portra" />

    <ListInput
      required
      max={200000}
      type="number"
      label="ISO"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('iso', e.target.value)}
      defaultValue={initialLogState.iso}
      placeholder="400" />

    <ListInput
      required
      maxLength={8}
      type="text"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('ss', e.target.value)}
      placeholder="1/150"
      defaultValue={initialLogState.ss}
      label="Shutter Speed" />

    <ListInput
      required
      maxLength={5}
      type="text"
      placeholder="16"
      defaultValue={initialLogState.f}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('f', e.target.value)}
      label="Apperture (f)" />

    <ListInput
      maxLength={140}
      label="Notes"
      type="textarea"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLog('notes', e.target.value)}
      defaultValue={initialLogState.notes}
      placeholder="Direct sunlight in a bright day" />
  </List>

  <Block>
    <Button large onClick={() => executeAction()}>{callToAction} Log</Button>
  </Block>

  <Toast
      position="left"
      opened={isToastOpen}
      button={
        <Button
          rounded
          clear
          small
          inline
          onClick={() => setToastOpen(false)}
        >
          Close
        </Button>
      }
    >
      <div className="shrink">{toastText}</div>
    </Toast>
</>
}
