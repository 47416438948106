import { useParams } from "react-router-dom"
import { LogForm } from "../LogForm"

import { LoadLog, TLog } from "../../Utils/data"

type TParams = {
  logId: string
}

export const LogEditPage = () => {
  const { logId } = useParams<keyof TParams>() as TParams
  const log: TLog = LoadLog(logId)

  return <>
    <LogForm log={log}/>
  </>
}
