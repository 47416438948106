import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom'
import { App, Page } from 'konsta/react'

import { CategoriesPage } from "./Components/CategoriesPage"
import { CategoryAddPage } from "./Components/CategoryAddPage"
import { CategoryPage } from "./Components/CategoryPage"
import { LogsPage } from "./Components/LogsPage"
import { LogPage } from "./Components/LogPage"
import { LogAddPage } from "./Components/LogAddPage"
import { LogEditPage } from "./Components/LogEditPage"
import { SharePage } from "./Components/SharePage"
import { ErrorPage } from './Components/ErrorPage'
import { TopNavigation } from "./Components/TopNavigation"
import { BottomNavigation } from "./Components/BottomNavigation"

import { Load } from './Utils/data'

import './styles.css';
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const Layout = () => {
  return <>
    <TopNavigation />
    <BottomNavigation />

    <div className="-mt-3 pb-10">
      <Outlet />
    </div>
  </>
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: 'root',
        path: "/",
        element: <CategoriesPage />,
        loader: () => {
          return {
            title: 'Categories',
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: 'categoryAdd',
        path: 'category/add',
        element: <CategoryAddPage />,
        loader: () => {
          return {
            title: 'Add Category',
            categories: Load('categories'),
          }
        }
      },
      {
        id: 'categories',
        path: "categories",
        element: <CategoriesPage />,
        loader: () => {
          return {
            title: 'Categories',
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: "category",
        path: "category/:categoryId",
        element: <CategoryPage />,
        loader: () => {
          return {
            title: 'Category Logs',
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: "logs",
        path: "logs",
        element: <LogsPage />,
        loader: () => {
          return {
            title: `Logs (${Load('logs').length > 0 ? Load('logs').length : '0'})`,
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: "logAdd",
        path: "log/add/:categoryId/:categoryName",
        element: <LogAddPage />,
        loader: () => {
          return {
            title: 'Add Log',
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: "log",
        path: "log/:logId",
        element: <LogPage />,
        loader: () => {
          return {
            title: 'Log',
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: "logEdit",
        path: "log/:logId/edit",
        element: <LogEditPage />,
        loader: () => {
          return {
            title: 'Edit Log',
            categories: Load('categories'),
            logs: Load('logs')
          }
        },
      },
      {
        id: "share",
        path: "share",
        element: <SharePage />,
        loader: () => {
          return {
            title: 'Stats & Share',
            share: {
              categories: Load('categories'),
              logs: Load('logs')
            }
          }
        },
      },
    ]
  },
])

root.render(
  <React.StrictMode>
    <App theme="ios">
      <Page>
        <RouterProvider router={router} />
      </Page>
    </App>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
