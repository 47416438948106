import {
  Block,
  BlockTitle,
  Button,
  Badge,
  List,
  ListItem,
} from 'konsta/react'
import { Link, useNavigate, useLoaderData, useLocation } from 'react-router-dom'

import { countItemsInCategory, IData } from '../../Utils/data'

export const CategoriesPage = () => {
  const data = useLoaderData() as IData
  const { categories, logs } = data;
  const navigate = useNavigate()
  const location = useLocation()

  const mockData = () => {
    const data = { "categories": [{ "id": "1", "name": "Cats" }, { "id": "2", "name": "Keyboards" }], "logs": [{ "id": "1", "image": "https://i.imgur.com/e6J1huX.png", "category_id": "1", "added": "2023-05-05T14:15:30.886Z", "name": "Mabel arrived!", "iso": "400", "ss": "1/125", "f": "16", "film": "Kodak Portra 400", "notes": "Mabel finally landed on the moon and she found a friend! ❤" }, { "id": "2", "image": "https://i.imgur.com/Aonaimh.jpg", "category_id": "2", "added": "2023-05-05T14:17:16.740Z", "name": "HHKB with Cacodemon", "iso": "800", "ss": "1/150", "f": "16", "film": "CineStill 800T", "notes": "During the pandemic I hacked this HHKB, adding a wireless logic board, with local SSD storage and this gorgeous Artisan keycap" }] }

    localStorage.setItem('categories', JSON.stringify(data.categories))
    localStorage.setItem('logs', JSON.stringify(data.logs))
    navigate(location)
  }

  if (categories && categories.length > 0) {
    return (
    <List strong inset>
        {categories.sort((a, b) => a.name.localeCompare(b.name)).map(category => {
        return <Link to={`category/${category.id}`} key={category.id}>
          <ListItem
            title={category.name}
            after={
              <Badge colors={{ bg: 'bg-gray-500' }}>{
                countItemsInCategory(category.id, logs)
              }</Badge>
            }
          />
        </Link>
      })}
      </List>
    )
  } else {
    return <>
      <BlockTitle>Welcome</BlockTitle>
      <Block strong outline inset>
        <p>Here you can log your photo settings in an effective way.
          Start by adding a category on the top right icon</p>
        <p>Or, for now, you can
          <Button onClick={mockData} className='mt-2'>Mock data</Button>
        </p>
      </Block>
    </>
  }
}
