import { useState } from "react"
import { Block, BlockTitle, Button, Icon, List, Link, ListItem, Navbar, Page, Popup, Toast, ListInput } from "konsta/react"
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { BiImport, BiExport } from "react-icons/bi"

import { Save } from "../../Utils/data"

export const SharePage = () => {
  const [isToastOpen, setToastOpen] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>('')
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false)
  const [backupContent, setBackupContent] = useState<string>('')
  const data = useLoaderData()
  const { share }: any = data;
  const navigate = useNavigate()
  const location = useLocation()

  const deleteAllData = () => {
    const confirm = window.confirm('Are you sure you want to delete ALL data? This can\'t be undone.')

    if (confirm) {
      localStorage.removeItem('categories')
      localStorage.removeItem('logs')

      navigate(location)
      setToastText('All data has been deleted')
      setToastOpen(true)
    }
  }

  const copyDataToClipboard = () => {
    const { categories, logs } = share
    const dataToBackup = {
      categories,
      logs
    }

    setToastOpen(true)
    setToastText('Your backup is available on your clipboard. Paste it somewhere safe to keep it.')
    return navigator.clipboard.writeText(JSON.stringify(dataToBackup))
  }

  const restoreBackup = () => {
    const confirmation = window.confirm('Are you sure you want to restore the backup data? All your current data will be LOST and REPLACED.\n\nThis action can not be undone.')

    if (confirmation) {
      setToastText('')
      const { categories, logs } = JSON.parse(backupContent)

      try {
        Save('categories', categories)
        Save('logs', logs)
        setToastText('Your backup has been recovered!')
      } catch (error) {
        setToastText('Something went wrong!')
      }

      navigate(location)
      setPopupOpen(false)
      setToastOpen(true)
    }
  }

  return <>
    <Toast
      position="left"
      opened={isToastOpen}
      button={
        <Button
          rounded
          clear
          small
          inline
          onClick={() => setToastOpen(false)}
        >
          Close
        </Button>
      }
    >
      <div className="shrink">{toastText}</div>
    </Toast>

    <Popup opened={isPopupOpen} onBackdropClick={() => setPopupOpen(false)}>
      <Page>
        <Navbar
          title="Restore Backup"
          right={
            <Link navbar onClick={() => setPopupOpen(false)}>
              Close
            </Link>
          }
        />

        <Block strong>
          <p>Paste your backup on the field below to restore it:</p>
        </Block>

        <List>
          <ListInput
            label="Backup String"
            type="textarea"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBackupContent(e.target.value)}
            placeholder="{{A lot of nonsense writings here! :D}}" />
        </List>

        <Block>
          <Button large onClick={restoreBackup}>Restore Backup</Button>
        </Block>
      </Page>
    </Popup>

    <BlockTitle>Sharing is Caring</BlockTitle>
    <Block strong>
      <p>Remember that this is a free and privacy-first app, not a single data of yours is transacted on the interwebs.</p>
    </Block>

    <BlockTitle>Statistics</BlockTitle>
    <List strong outline inset>
      <ListItem title="Categories" after={share.categories?.length || 0} />
      <ListItem title="Logs" after={share.logs?.length || 0} />
      <ListItem title="Hugs you owe the developer" after={
        Math.floor(Math.random() * (99 - 22) + 22)
      } />
    </List>

    <BlockTitle>Backup</BlockTitle>
    <Block strong outlineIos className="space-y-2">
      <div className="grid grid-cols-2 gap-x-4">
        <Button large onClick={() => setPopupOpen(true)}>
          <Icon><BiImport className="w-5 h-5 mr-1" /></Icon>
          Restore Backup
        </Button>
        <Button large onClick={copyDataToClipboard}>
          <Icon><BiExport className="w-5 h-5 mr-1" /></Icon>
          Export Backup
        </Button>
      </div>
    </Block>

    <BlockTitle>DESTRUCTIVE!</BlockTitle>
    <Block strong outlineIos>
      <Button large onClick={deleteAllData}>
        Delete all data!
      </Button>
    </Block>
  </>
}
