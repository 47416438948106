export type TLog = {
  id: string
  image?: string
  category_id: string
  added: string
  name: string
  iso: number
  ss: string
  f: string
  film?: string
  notes?: string
}

export type TCategory = {
  id: string
  name: string
}

export interface IData {
  categories: TCategory[]
  logs: TLog[]
}

export type TSortingOrder = 'asc' | 'desc'

/**
 * Persists data as a whole.
 * Important to be aware that this save is not partial, you can only save the whole data for each field
 * @param {string} field
 * @param {TLog[] | TCategory[]} data
 * @returns {TLog[] | TCategory[]}
 */
export const Save = (field: string, data: any) => {
  try {
    return localStorage.setItem(field, JSON.stringify(data))
  } catch(error) {
    return alert(`Problem saving data: \n${error}`)
  }
}

/**
 * Load persisted data to be used.
 * Field options are: 'logs' | 'categories'
 * @param {string} field
 * @returns {TLog[] | TCategory[]}
 */
export const Load = (field: string) => {
  const data = localStorage.getItem(field)
  try {
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
  } catch(error) {
    return alert(`Problem loading data: \n${error}`)
  }
}

/**
 * Return all data from a log based on its id
 * @param {string} id
 * @returns {TLog}
 */
export const LoadLog = (id: string): TLog => {
  return Load('logs').filter((log: TLog) => log.id === id).at(0)
}

/**
 * Returns the next ID for a Log or Category
 * @param {TLog[] | TCategory[]} collection Collection of Logs or Categories (TLog[] or TCategory[])
 * @returns {string} Next sequencial id
 */
export const generateId = (collection: TLog[] | TCategory[]): string => {
  if (!collection) throw new Error("You need to send an array of Logs or Categories");

  const allIds = collection.map((item: TLog | TCategory) => Number(item.id))
  const lastId = allIds.at(-1) || 0
  const newId = lastId + 1

  return newId.toString()
}

/**
 * Add a new category
 * @param {TCategory} categoryData
 */
export const addCategory = (categoryData: TCategory) => {
  try {
    const allCategories = Load('categories') || [];

    const newLogData = {
      ...categoryData,
    }

    allCategories.push(newLogData)

    Save('categories', allCategories)
  } catch (error) {
    throw new Error(`Something went wrong trying to add a new category:\n\n${error}`);
  }
}

/**
 * Persists a new Log to the collection
 * @param {TLog} logData
 */
export const addLog = (logData: TLog) => {
  try {
    const allLogs = Load('logs') || [];

    const newLogData = {
      ...logData,
      id: generateId(allLogs),
      added: new Date()
    }

    allLogs.push(newLogData)

    Save('logs', allLogs)
  } catch (error) {
    throw new Error(`Something went wrong trying to save your Log:\n\n${error}`);

  }
}

/**
 * Remove log from collection
 * @param {string} logId
 */
export const deleteLog = (logId: string) => {
  try {
    const allLogs = Load('logs')
    const allLogsWithoutDeleted = allLogs.filter((log: TLog) => log.id !== logId)
    Save('logs', allLogsWithoutDeleted)
  } catch (error) {
    throw new Error(`Something went wrong trying to delete your Log:\n\n${error}`);
  }
}

/**
 * Edit and save changes to a log
 * @param {string} id id of the log to be changed
 * @param {TLog} log new data for the log
 */
export const editLog = (id: string, log: TLog) => {
  try {
    const allLogs = Load('logs').filter((log: TLog) => log.id !== id)
    allLogs.push(log)
    Save('logs', allLogs)
  } catch (error) {
    throw new Error(`Something went wrong trying to edit your Log #${id}:\n\n${error}`);
  }
}

/**
 * Returns the quantity of Logs in a given category
 * @param {string} category
 * @param {TLog[]} logs
 * @returns {number} Number of logs in a category
 */
export const countItemsInCategory = (category: string, logs: TLog[]): number => {
  let entryCounter = 0;
  if (!logs) return 0;

  for (let e = 0; e < logs.length; e += 1) {
    if (logs[e].category_id === category) {
      entryCounter += 1;
    }
  }

  return entryCounter;
}

/**
 * Return an Array of all Logs from specific category
 * @param {string} categoryId
 * @returns {TLog[]}
 */
export const logsFromCategory = (categoryId: string): TLog[] => {
  const allLogs = Load('logs') || []
  const logs: TLog[] = []

  allLogs.forEach((log: TLog) => {
    if (log.category_id === categoryId) {
      logs.push(log);
    }
  })

  return logs;
}

/**
 * Return the category name of the correspondent id
 * @param {string} categoryId
 * @returns {string}
 */
export const getCategoryName = (categoryId: string): string => {
  const categories = Load('categories')
  let categoryName: string = ''

  if (categories.length < 1) return ''

  for (let i = 0; i < categories.length; i += 1) {
    if (categoryId && categories[i].id === categoryId) {
      categoryName = categories[i].name;
    }
  }

  return categoryName;
}
